import './App.css'
import { Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Introduction from './components/Introduction'
import Contact from './components/Contact'
import About from './components/About'
import Projects from './components/Projects'

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Routes>
          <Route exact path="/" element={<Introduction />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/projects" element={<Projects />} />
        </Routes>
      </main>
    </div>
  )
}

export default App
