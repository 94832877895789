import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Header() {
  return (
    <div>
      <nav className="navlinks">
        <NavLink className="link" to="/">
          home
        </NavLink>
        <NavLink className="link" to="/projects">
          projects
        </NavLink>
        <NavLink className="link" to="/about">
          about me
        </NavLink>
        <NavLink className="link" to="/contact">
          contact
        </NavLink>
      </nav>
    </div>
  )
}
