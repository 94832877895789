import React from 'react'
import './Contact.css'

export default function Contact() {
  return (
    <div>
      <div className="contact-box">
        <a href="mailto:anderson.hayden@gmail.com">
          <h1 id="contact-header" className="tg-header">
            Contact Me
          </h1>
          <img
            className="contact-img"
            src="https://i.imgur.com/oX1TZZx.jpg"
            alt="Pink abstract art"
          />
        </a>
        <p>
          <b>email:</b> anderson (dot) hayden (at) gmail (dot) com
        </p>
        <p>
          <b>phone:</b> 817.894.0858
        </p>
      </div>
    </div>
  )
}
