import React from 'react'

export default function Introduction() {
  return (
    <div className="introduction">
      <div className="introbox">
        <div>
          <img
            className="introimage"
            src="https://i.imgur.com/JSAj3Ku.jpg"
            alt="drawing"
          />
        </div>
        <div className="introtextcard">
          <h1 id="intro-header" className="tg-header">
            Hayden Anderson
          </h1>

          <p className="introtext">
            I'm a full-stack software engineer and artist based out of New York
            City.
            <br />
            <br />
            <b>Languages:</b> JavaScript, HTML, CSS, Python
            <br />
            <b>Frameworks:</b> React.js, Vue, Express
            <br />
            <b>Database:</b> PostgresSQL, MongoDB, Flask
          </p>
        </div>
      </div>
    </div>
  )
}
