import React from 'react'
import './About.css'

export default function About() {
  return (
    <div className="about-page">
      <div className="about-container">
        <h1 className="tg-header">About Me</h1>
        <p className="about-p">
          I am a software engineer with a background in communications and
          visual art administration. I've had a previous career in art book
          publishing and various arts administration roles, including working
          directly for an artist’s studio. An interest in music technology led
          me to fall back in love with computers and learn to code. Going
          forward, I plan to use my creative background to build
          forward-thinking technologies that provide meaningful and memorable
          user experiences that enhance, rather than limit, our human realities
        </p>
        <a
          className="about-links"
          href="https://docs.google.com/document/d/1QSCxCoRePYlFSd41BrJyEx-XZMki-bTGF7jb2myGMYM/edit"
        >
          <b> Download Resume </b>
        </a>
        <a
          className="about-links"
          href="https://www.linkedin.com/in/hayden-anderson-909/"
        >
          <b> LinkedIn </b>
        </a>
        <a className="about-links" href="https://github.com/hayden707">
          <b> GitHub </b>
        </a>
      </div>
    </div>
  )
}
