import React from 'react'
import './Projects.css'

export default function Projects() {
  return (
    <div className="projects-page">
      <div className="projects-container">
        <div className="project00">
          <a href="https://crate-dig.surge.sh/">
            <h2 className="tg-header">Crate Dig</h2>
            <p className="p00-p">
              A front-end application that let's users search for album release
              information using the Discogs API. Built as a personal project to
              specifically practice React, 3rd party API integration, and the
              CSS framework Tailwind.
            </p>
            <img
              src="https://i.imgur.com/H03EXI2.png"
              alt="Homepage of Crate Dig"
              className="project00-img"
            />
          </a>
          <div className="port-links">
            <a className="link" href="https://crate-dig.surge.sh/">
              Link to deployed site
            </a>
            <a
              className="link"
              href="https://github.com/hayden707/discogs_in_space"
            >
              GitHub
            </a>
          </div>
        </div>

        <div className="project01">
          <a href="https://aquatic-chords.netlify.app/">
            <h2 className="tg-header">Aquatic Chords</h2>
            <p className="p01-p">
              A synthesizer built with Tone.js and React which allows users to
              play with the chords from "Aquatic Ambience" by David Wise from
              the game Donkey Kong Country.
            </p>
            <img
              src="https://i.imgur.com/1Ak2IEC.png"
              alt="Homepage of Aquatic Chords"
              className="project01-img"
            />
          </a>
          <div className="port-links">
            <a className="link" href="https://aquatic-chords.netlify.app/">
              Link to deployed site
            </a>
            <a
              className="link"
              href="https://github.com/hayden707/aquatic_chords"
            >
              GitHub
            </a>
          </div>
        </div>
        <div className="project1">
          <a href="https://makeitpoetry.herokuapp.com/">
            <h2 className="tg-header">MakePoetry</h2>
            <p className="p1-p">
              A generative poetry app that turns any text under 500 words into a
              randomized poem. Developed with Vue, and a PosgresSQL database.
            </p>
            <img
              src="https://i.imgur.com/zmlEA72.png"
              alt="Homepage of MakePoetry"
              className="project1-img"
            />
          </a>
          <div className="port-links">
            <a className="link" href="https://makeitpoetry.herokuapp.com/">
              Link to deployed site
            </a>
            <a
              className="link"
              href="https://github.com/hayden707/MakeItPoetry"
            >
              GitHub
            </a>
          </div>
        </div>
        <div className="project2">
          <a href="https://reviewrrr.herokuapp.com/">
            <h2 className="tg-header">Reviewr</h2>
            <p className="p2-p">
              Developed as part of a three person team in a one week hackathon,
              Reviewr is a social media site for reviewing albums, built with
              React.js, Express, PostgresSQL, and Node.js utilizing the Deezer
              API and a secure access token handling system.
            </p>
            <img
              src="https://i.imgur.com/YftcyFF.png"
              alt="Homepage of Reviewr"
              className="project2-img"
            />
          </a>
          <div className="port-links">
            <a className="link" href="https://reviewrrr.herokuapp.com/">
              Link to deployed site
            </a>
            <a className="link" href="https://github.com/hayden707/Reviewr">
              GitHub
            </a>
          </div>
        </div>
        <div className="project3">
          <a href="https://rpgelclasico.surge.sh/">
            <h2 className="tg-header">RPG El Clasico</h2>
            <p className="p3-p">
              Created as an update to my first ever web project, RPG El Clasico
              is a simple, soccer themed RPG turn-based battle system built with
              HTML, CSS, JavaScript, and Node.js.
            </p>
            <img
              src="https://i.imgur.com/qMd1Z8M.png"
              alt="Homepage of Reviewr"
              className="project2-img"
            />
          </a>
          <div className="port-links">
            <a className="link" href="https://rpgelclasico.surge.sh/">
              Link to deployed site
            </a>
            <a
              className="link"
              href="https://github.com/hayden707/rpg_el_clasico"
            >
              GitHub
            </a>
          </div>
        </div>
        <div className="project4">
          <a href="https://bossfight.surge.sh/">
            <h2 className="tg-header">Boss Fight</h2>
            <p className="p4-p">
              My first ever web project, a classic RPG battle system created
              with Javascript, CSS, HTML, and Node.js.
            </p>
            <img
              src="https://i.imgur.com/82o1tOX.png"
              alt="Homepage for Boss Fight"
              className="project3-img"
            />
          </a>
          <div className="port-links">
            <a className="link" href="https://bossfight.surge.sh/">
              Link to deployed site
            </a>
            <a className="link" href="https://github.com/hayden707/BOSS_FIGHT">
              GitHub
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
